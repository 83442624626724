import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, HStack, Input, List, ListItem, Switch, Text, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { allSupportedFormats, appColors, } from '../../../../../config/constants';
import { PhotoDate } from '../PhotoGalleryModal/PhotoDate';
import { PhotoTags } from '../PhotoGalleryModal/PhotoTags';
import { validateImageFormat } from '../utility/Validations';
import { useAppSelector } from '@/store/hooks';
import { selectOrgRole, orgRoleCanEdit } from '@/store/userSlice';
export const AddPhotoModalBody = ({ photoTaken, renderCamera, renderPhotoView, onTakePhoto, photosRef, photos, onRetakePhoto, onSavePhoto, onUpdateState, handleTagSelect, handleTagDelete, register, tagsToDisplay, error, setError, setInvalidDate, setSelectedDate, }) => {
    const [viewPhotos, setViewPhotos] = useState(false);
    const orgRole = useAppSelector(selectOrgRole);
    const canEdit = orgRoleCanEdit(orgRole);
    const toggleViewPhotos = () => {
        setViewPhotos(!viewPhotos);
    };
    useEffect(() => {
        const unsupportedFilesList = validateImageFormat(photos, allSupportedFormats);
        if (unsupportedFilesList) {
            const errorMessage = `Unsupported file format. Please upload files in one of the following formats: ${allSupportedFormats.join(', ')}.`;
            setError(errorMessage);
        }
        else {
            setError(null);
        }
    }, [photos]);
    return (_jsxs(Flex, Object.assign({ flexDirection: 'column' }, { children: [!photoTaken && !viewPhotos ? renderCamera : renderPhotoView, _jsxs(Flex, Object.assign({ justifyContent: 'space-between', alignItems: 'center', alignSelf: viewPhotos ? 'end' : '' }, { children: [!viewPhotos && (_jsxs(Flex, Object.assign({ flexWrap: 'wrap', mt: '20px', mb: '15px', gap: '10px', alignItems: 'center' }, { children: [!photoTaken ? (_jsx(Button, Object.assign({ variant: variants.transparentBlueBtn, height: '36px', mr: '25px', onClick: onTakePhoto }, { children: "Take photo" }))) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: variants.transparentBlueBtn, height: '36px', mr: '25px', onClick: onRetakePhoto }, { children: "Retake Photo" })), navigator.onLine && (_jsx(Button, Object.assign({ variant: variants.transparentBlueBtn, height: '36px', mr: '25px', onClick: onSavePhoto }, { children: "Save Photo" })))] })), !photoTaken && (_jsx(Button, Object.assign({ variant: variants.transparentBlueBtn, height: '36px', mr: '60px', onClick: () => {
                                    var _a;
                                    (_a = photosRef === null || photosRef === void 0 ? void 0 : photosRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                } }, { children: "Upload Photo(s)" }))), _jsx(Input, { type: 'file', multiple: true, style: { display: 'none' }, accept: 'image/*,video/*', onChange: () => onUpdateState(photosRef), ref: photosRef })] }))), !photoTaken && photos.length > 0 && (_jsxs(HStack, Object.assign({ mt: viewPhotos ? '28px' : '0px' }, { children: [_jsx(Text, Object.assign({ fontSize: '14px', mr: '20px' }, { children: "Preview photos" })), _jsx(Switch, { variant: 'orange-switch', size: 'md', onChange: toggleViewPhotos })] })))] })), _jsxs(Flex, Object.assign({ mt: viewPhotos ? '15px' : '0px' }, { children: [_jsxs(Box, { children: [_jsx(Text, Object.assign({ mr: '20px' }, { children: "Files being uploaded:" })), photos.length === 0 && (_jsxs(Text, Object.assign({ mr: '20px', fontSize: '12px', color: appColors.TEXT_SEC_DARK_GRAY }, { children: ["Supported formats: ", allSupportedFormats.join(', ')] })))] }), _jsx(List, { children: photos.map((file, i) => {
                            return (_jsx(ListItem, { children: file.name }, `${file} ${i}`));
                        }) })] })), _jsx(Text, Object.assign({ variant: variants.error, p: '0px', mt: '5px' }, { children: error })), _jsxs(Box, Object.assign({ p: '20px', bg: `${appColors.SEC_LIGHT_GREY}`, w: '100%', borderRadius: '5px', mt: '20px' }, { children: [_jsx(PhotoDate, { setInvalidDate: setInvalidDate, setSelectedDate: setSelectedDate }), _jsx(PhotoTags, { handleTagSelect: handleTagSelect, handleTagDelete: handleTagDelete, register: register, tagsToDisplay: tagsToDisplay, canEdit: canEdit })] }))] })));
};
