import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Select, Text } from '@chakra-ui/react';
import { VscTriangleDown } from 'react-icons/vsc';
import { appColors } from '../../../../../config/constants';
import { slgqColors } from '../../../../../config/theme';
import { usePhotoContext } from '../../../../../contexts/Photo/PhotoContext';
import { TagCard } from './TagCard';
export const PhotoTags = ({ canEdit, handleTagSelect, handleTagDelete, register, tagsToDisplay, }) => {
    const { orgTags } = usePhotoContext();
    const selectOptions = orgTags.filter((orgTag) => !(tagsToDisplay === null || tagsToDisplay === void 0 ? void 0 : tagsToDisplay.some((photoTag) => orgTag.tagIdentity === photoTag.tagIdentity)));
    return (_jsxs(Flex, Object.assign({ alignItems: 'center', flexWrap: 'wrap' }, { children: [navigator.onLine && canEdit && (_jsxs(Flex, Object.assign({ flexDirection: 'column', alignItems: 'center' }, { children: [_jsx(Text, Object.assign({ mb: '5px', ml: '20px', color: slgqColors.primBlue, fontSize: '14px', fontWeight: '400', alignSelf: 'flex-start' }, { children: "Add tag(s)" })), _jsx(Box, Object.assign({ w: '300px' }, { children: _createElement(Select, Object.assign({ placeholder: 'Select' }, register('tag'), { onChange: handleTagSelect, border: `1px solid ${appColors.PRIM_LIGHT_GREY}4D`, borderRadius: '50px', fontSize: '14px', fontWeight: '400', w: '240px', icon: _jsx(VscTriangleDown, { fontSize: '12px' }), 
                            // This key rerenders options when they changed on a tablet
                            key: `photo tag selector - ${selectOptions.length}` }), selectOptions.map((tag) => (_jsx("option", Object.assign({ value: tag.tagIdentity }, { children: tag.name }), `photo tag - ${tag.name}`)))) }))] }))), _jsx(Flex, Object.assign({ flexWrap: 'wrap', mt: '15px' }, { children: tagsToDisplay &&
                    tagsToDisplay.map((tag, index) => (_jsx(TagCard, { color: tag.color, name: tag.name, handleTagDelete: handleTagDelete, tagToDelete: tag.tagIdentity, canEdit: canEdit }, `assigned tag - ${index}`))) }))] })));
};
