import { measureColors } from '../config/constants';
export const findSpaceName = (asset, facilityEvr) => {
    let foundSpaceName = '';
    facilityEvr.plans &&
        facilityEvr.plans.forEach((plan) => {
            plan.spaces &&
                plan.spaces.forEach((space) => {
                    if (space.spaceIdentity === asset.spaceId) {
                        foundSpaceName = space.spaceId;
                    }
                });
        });
    return foundSpaceName;
};
export const convertRatingToHex = (rating) => {
    switch (rating) {
        case 'Green':
            return measureColors.MEASURE_GREEN;
        case 'Yellow':
            return measureColors.MEASURE_YELLOW;
        case 'Red':
            return measureColors.MEASURE_RED;
        case 'Black':
            return measureColors.MEASURE_BLACK;
        case 'Blue':
            return measureColors.MEASURE_BLUE;
        case 'Orange':
            return measureColors.MEASURE_ORANGE;
        default:
            return '';
    }
};
export const toCsvString = (stringToWrap) => {
    if (!stringToWrap)
        return '';
    stringToWrap = stringToWrap.replace(/"/g, '\u2033');
    return `"${stringToWrap}"`;
};
