import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { slgqColors, variants } from '@/config/theme';
import { Button, ButtonGroup, Grid, GridItem, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToken, VStack, } from '@chakra-ui/react';
import { FloorPlanCanvas, } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
import CustomSelect from '@frontend/design-system/components/Inputs/CustomSelect';
import { MeasureSquare } from '@frontend/design-system/components/MeasureSquare/MeasureSquare';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DynamicInput } from '../../Inputs/DynamicInput';
export const SelectSpacesModal = ({ handleSubmit, isOpen, onClose, onSelect, handleBackOrCancel, spaces, selectedSpaces, shapes, floorPlanImage, onClear, plans, selectedPlan, onPlanSelect, isLoading = false, }) => {
    const [width, setWidth] = useState(0);
    const [, setIsMounted] = useState(false);
    const floorplanRef = useRef(null);
    const [yellow, secOrange] = useToken('colors', [
        'slgq.yellow',
        'slgq.secOrange',
    ]);
    useLayoutEffect(() => {
        if (floorplanRef.current) {
            const { width } = floorplanRef.current.getBoundingClientRect();
            setWidth(width);
        }
    }, [floorplanRef.current]);
    useEffect(() => {
        setIsMounted(true);
    }, [floorplanRef.current]);
    const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            setWidth(entry.contentRect.width);
        });
    });
    useEffect(() => {
        if (floorplanRef.current) {
            resizeObserver.observe(floorplanRef.current);
        }
        return () => {
            floorplanRef.current &&
                resizeObserver.unobserve(floorplanRef.current);
            resizeObserver.disconnect();
        };
    }, [floorplanRef.current]);
    return (_jsxs(Modal, Object.assign({ closeOnEsc: false, closeOnOverlayClick: false, isOpen: isOpen, isCentered: true, onClose: onClose, size: '5xl' }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.2)' }), _jsxs(ModalContent, Object.assign({ padding: '40px' }, { children: [_jsx(ModalHeader, Object.assign({ color: slgqColors.secGrey, fontSize: '36px', fontWeight: 400, padding: '0px' }, { children: "Paste in" })), _jsxs(ModalBody, Object.assign({ p: '0px', mt: '20px', mb: '40px' }, { children: [_jsx(Text, { children: "Photos are not included in copy & paste." }), _jsxs(Grid, Object.assign({ templateColumns: 'repeat(3, 1fr)', gap: 3 }, { children: [_jsx(GridItem, Object.assign({ colSpan: 1 }, { children: _jsxs(VStack, Object.assign({ gap: 2, w: 'full', alignItems: 'start' }, { children: [_jsx("p", { children: "Spaces" }), _jsx(VStack, Object.assign({ style: {
                                                        width: '100%',
                                                        maxHeight: '300px',
                                                        overflowY: 'auto',
                                                        border: '1px solid #C0C2C1',
                                                        borderRadius: '4px',
                                                        padding: '10px',
                                                    } }, { children: spaces.length ? (spaces.map((space, key) => {
                                                        {
                                                            return (_jsx(DynamicInput, { data: selectedSpaces.includes(space.identity), editMode: true, inputType: 'checkbox', label: space.name, handleInputChange: () => onSelect(space) }, key));
                                                        }
                                                    })) : (_jsx("p", { children: "No spaces on this plan" })) }))] })) })), _jsxs(GridItem, Object.assign({ colSpan: 2, ref: floorplanRef }, { children: [_jsxs(HStack, Object.assign({ display: 'flex', justifyContent: 'space-between', w: 'full' }, { children: [_jsx(CustomSelect, { options: plans, defaultOption: selectedPlan, onSelectOption: onPlanSelect }), _jsxs("div", { children: [_jsx(MeasureSquare, { color: `${secOrange}BF`, text: 'Selected', border: false, rounded: true }), _jsx(MeasureSquare, { color: `${yellow}BF`, text: 'Not Selected', border: false, rounded: true })] })] })), _jsx(FloorPlanCanvas, { containerWidth: width, planImageUrl: floorPlanImage, pins: [], splitShapes: [], shapes: shapes, isPinSelected: () => false })] }))] }))] })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: '100%' }, { children: [_jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ fontSize: '14px', onClick: handleBackOrCancel.handler, onTouchEnd: handleBackOrCancel.handler, variant: variants.transparentRedBtn, type: 'button' }, { children: handleBackOrCancel.label })), _jsx(Button, Object.assign({ fontSize: '14px', onClick: onClear, onTouchEnd: onClear, variant: variants.transparentBlueBtn, type: 'button' }, { children: "Clear all" }))] }), _jsx(Button, Object.assign({ color: slgqColors.primWhite, fontSize: '14px', onClick: handleSubmit, onTouchEnd: handleSubmit, type: 'button', isDisabled: selectedSpaces.length > 0 && !isLoading
                                        ? false
                                        : true, variant: variants.blueBtn, _hover: { variant: variants.blueBtn } }, { children: "Paste" }))] })) }))] }))] })));
};
